.c-dropdown {
  display: inline-block;
}

  .c-dropdown .c-dropdown__toggle {
    width: inherit;
    cursor: pointer;
  }

  .c-dropdown__window {
  position: absolute;
  top: 0px;
  left: 0px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;

    z-index: calc(var(--z-index-top) + 1);
}

  .c-dropdown__link a,
    .c-dropdown__link .button_to > [type="submit"] {
  display: block;
  width: 100%;
  text-align: left;
  text-transform: none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity, 1));
  line-height: 1.25rem;
}

  @media (min-width: 1024px) {

  .c-dropdown__link a,
    .c-dropdown__link .button_to > [type="submit"] {
    white-space: nowrap;
  }
}

  .c-dropdown__link a:hover, .c-dropdown__link .button_to > [type="submit"]:hover {
        color: white;
        background: #2563eb;
      }

  .c-dropdown__link a.disabled, .c-dropdown__link .button_to > [type="submit"].disabled {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity, 1));
  cursor: not-allowed;
}

  .c-dropdown__link .button_to {
  display: block;
  width: 100%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vZHJvcGRvd25fY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHFCQUFxQjtBQXdDdkI7O0VBdENFO0lBQ0UsY0FBYztJQUNkLGVBQWU7RUFDakI7O0VBR0U7RUFBQSxrQkFBNEI7RUFBNUIsUUFBNEI7RUFBNUIsU0FBNEI7RUFDNUIsb0JBQVc7RUFBWCx1QkFBVzs7SUFFWDtBQUg0Qjs7RUFTMUI7O0VBQUEsY0FBbUI7RUFBbkIsV0FBbUI7RUFDbkIsZ0JBQWdCO0VBQ2hCLG9CQUFrQjtFQUNsQixrQkFBZ0I7RUFBaEIsbUJBQWdCO0VBQWhCLG1CQUFnQjtFQUFoQixzQkFBZ0I7RUFDaEIsbUJBQTRCO0VBQTVCLG9CQUE0QjtFQUE1QixnREFBNEI7RUFDNUI7QUFMbUI7O0VBTW5COztFQUFBOztJQUFBO0VBQTJCO0FBQUE7O0VBRTNCO1FBQ0UsWUFBWTtRQUNaLG1CQUFvQztNQUN0Qzs7RUFHRTtFQUFBLG9CQUFvQjtFQUFwQixtREFBb0I7RUFDcEI7QUFEb0I7O0VBTXRCO0VBQUEsY0FBbUI7RUFBbkI7QUFBbUIiLCJmaWxlIjoiLi4vcGlzdGFjaGlvL2FwcC9jb21wb25lbnRzL3Bpc3RhY2hpby9kcm9wZG93bl9jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmMtZHJvcGRvd24ge1xuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG5cbiAgLmMtZHJvcGRvd25fX3RvZ2dsZSB7XG4gICAgd2lkdGg6IGluaGVyaXQ7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xuICB9XG5cbiAgJl9fd2luZG93IHtcbiAgICBAYXBwbHkgYWJzb2x1dGUgdG9wLTAgbGVmdC0wO1xuICAgIEBhcHBseSBweS0xO1xuXG4gICAgei1pbmRleDogY2FsYyh2YXIoLS16LWluZGV4LXRvcCkgKyAxKTtcbiAgfVxuXG4gICZfX2xpbmsge1xuICAgIGEsXG4gICAgLmJ1dHRvbl90byA+IFt0eXBlPVwic3VibWl0XCJdIHtcbiAgICAgIEBhcHBseSBibG9jayB3LWZ1bGw7XG4gICAgICBAYXBwbHkgdGV4dC1sZWZ0O1xuICAgICAgQGFwcGx5IG5vcm1hbC1jYXNlO1xuICAgICAgQGFwcGx5IHB4LTQgcHktMjtcbiAgICAgIEBhcHBseSB0ZXh0LXNtIHRleHQtZ3JheS03MDA7XG4gICAgICBAYXBwbHkgbGVhZGluZy01O1xuICAgICAgQGFwcGx5IGxnOndoaXRlc3BhY2Utbm93cmFwO1xuXG4gICAgICAmOmhvdmVyIHtcbiAgICAgICAgY29sb3I6IHdoaXRlO1xuICAgICAgICBiYWNrZ3JvdW5kOiB0aGVtZSgnY29sb3JzLmJsdWUuNjAwJyk7XG4gICAgICB9XG5cbiAgICAgICYuZGlzYWJsZWQge1xuICAgICAgICBAYXBwbHkgdGV4dC1ncmF5LTQwMDtcbiAgICAgICAgQGFwcGx5IGN1cnNvci1ub3QtYWxsb3dlZDtcbiAgICAgIH1cbiAgICB9XG5cbiAgICAuYnV0dG9uX3RvIHtcbiAgICAgIEBhcHBseSBibG9jayB3LWZ1bGw7XG4gICAgfVxuICB9XG59XG4iXX0= */