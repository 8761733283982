.c-receivable_pdf-download-button {

    position: relative;

    display: flex;

    align-items: center;

    justify-content: center;

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    --tw-bg-opacity: 1;

    background-color: rgb(228 228 231 / var(--tw-bg-opacity, 1));

    border-radius: 9999px;

    font-size: 1.5rem;

    line-height: 2rem;

    --tw-text-opacity: 1;

    color: rgb(82 82 91 / var(--tw-text-opacity, 1))
}

  .c-receivable_pdf-download-button__arrow {

    position: absolute;

    font-size: 0.875rem;

    line-height: 1.25rem;

    top: 1.1rem;
    left: 1.4rem
}

  .c-receivable_pdf-download-button:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(212 212 216 / var(--tw-bg-opacity, 1))
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL3JlY2VpdmFibGUvcGRmX2Rvd25sb2FkX2J1dHRvbl9jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFOztJQUFBLGtCQUFlOztJQUNmLGFBQXVDOztJQUF2QyxtQkFBdUM7O0lBQXZDLHVCQUF1Qzs7SUFDdkMscUJBQWdCOztJQUFoQixzQkFBZ0I7O0lBQWhCLG1CQUFnQjs7SUFBaEIsc0JBQWdCOztJQUNoQixrQkFBa0I7O0lBQWxCLDREQUFrQjs7SUFDbEIscUJBQW1COztJQUNuQixpQkFBNkI7O0lBQTdCLGlCQUE2Qjs7SUFBN0Isb0JBQTZCOztJQUE3QjtBQUxlOztFQVFiOztJQUFBLGtCQUFlOztJQUNmLG1CQUFjOztJQUFkLG9CQUFjOztJQUVkLFdBQVc7SUFDWDtBQUplOztFQVFmOztJQUFBLGtCQUFrQjs7SUFBbEI7QUFBa0IiLCJmaWxlIjoiYXBwL2NvbXBvbmVudHMvcmVjZWl2YWJsZS9wZGZfZG93bmxvYWRfYnV0dG9uX2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYy1yZWNlaXZhYmxlX3BkZi1kb3dubG9hZC1idXR0b24ge1xuICBAYXBwbHkgcmVsYXRpdmU7XG4gIEBhcHBseSBmbGV4IGl0ZW1zLWNlbnRlciBqdXN0aWZ5LWNlbnRlcjtcbiAgQGFwcGx5IHB4LTMgcHktMjtcbiAgQGFwcGx5IGJnLWdyYXktMjAwO1xuICBAYXBwbHkgcm91bmRlZC1mdWxsO1xuICBAYXBwbHkgdGV4dC0yeGwgdGV4dC1ncmF5LTYwMDtcblxuICAmX19hcnJvdyB7XG4gICAgQGFwcGx5IGFic29sdXRlO1xuICAgIEBhcHBseSB0ZXh0LXNtO1xuXG4gICAgdG9wOiAxLjFyZW07XG4gICAgbGVmdDogMS40cmVtO1xuICB9XG5cbiAgJjpob3ZlciB7XG4gICAgQGFwcGx5IGJnLWdyYXktMzAwO1xuICB9XG59XG4iXX0= */